export var apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
export var apiAuth = '/oauth/token';
export var apiVersion = '/api/v3';
export var apiVersionParent = '/api/parent/v1';

export default {
    install: (app) => {
        app.config.globalProperties.$formatDate = (date) => {
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();

            return yyyy + '/' + mm + '/' + dd;
        };
        app.config.globalProperties.$formatTime = (date) => {
            var hh = String(date.getHours()).padStart(2, '0');
            var mm = String(date.getMinutes()).padStart(2, '0');
            var ss = String(date.getSeconds()).padStart(2, '0');
            return hh + ':' + mm + ':' + ss;
        };
    }
}