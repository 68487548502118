export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WebPOS"])},
  "buttons": {
    "view": {
      "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk bestelling"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk categorieën"])},
      "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk merken"])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk producten"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recente aankopen"])},
      "recentList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk lijst"])}
    },
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koop nu"])}
  },
  "pinPopup": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
    "noPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen pin ingesteld. U kan een pin instellen in de"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paswoord"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen..."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login of paswoord incorrect"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom terug ###USER###"])}
  },
  "favorites": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorieten"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft op dit moment nog geen favorieten. U kunt ze markeren door op de ster op de producten te klikken."])}
  },
  "recent": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recente aankopen laden..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft geen recente aankopen."])}
  },
  "recentList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WebPOS aankopen"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aankooplijst laden..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft geen aankopen op ###DATE###"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestel id"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])}
  },
  "search": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten gevonden."])},
    "noInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve een zoekterm in te geven."])}
  },
  "browse": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse"])}
  },
  "browseCategories": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën laden..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten gevonden."])}
  },
  "browseBrands": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merken laden..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten gevonden."])}
  },
  "browseProducts": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten laden..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten gevonden."])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen opgeslagen."])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen opslaan"])},
    "currentPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidige pin"])},
    "newPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe pin"])},
    "newPin2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe pin bevestigen"])},
    "removeCurrentPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin verwijderen"])},
    "forgotPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin vergeten (uitloggen)"])},
    "noInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve een nieuwe pin in te geven."])},
    "incorrectPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrecte huidige pin."])},
    "pinsNoMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe pincodes komen niet overeen."])},
    "loadingCreditWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krediet waarschuwing laden..."])},
    "creditWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krediet waarschuwing"])},
    "creditWarningError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets fout gegaan, probeer het opnieuw."])},
    "creditWarningLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De waarde moet tussen 0 en 100000 zijn."])},
    "reportmails": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport e-mails"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport e-mails laden..."])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten gevonden."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarschuwing voor laag saldo"])},
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagelijks verkooprapport"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wekelijks verkooprapport"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks verkooprapport"])},
      "balanceShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
      "dailyShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagelijks"])},
      "weeklyShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wekelijks"])},
      "monthlyShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail toevoegen"])},
      "loadingAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail toevoegen..."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
      "loadingRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail verwijderen..."])},
      "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig e-mailadres."])},
      "noReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U moet minstens één rapportageoptie selecteren."])},
      "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets fout gegaan, probeer het opnieuw."])}
    }
  },
  "buy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopen"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen producten geselecteerd."])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde"])},
    "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer ontvanger"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail aankoper"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])},
    "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige productwaarde."])},
    "invalidTelephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig telefoonnummer ontvanger."])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig E-mailadres aankoper."])},
    "invalidPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkeerde pin."])},
    "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis, probeer het opnieuw."])}
  },
  "viewOrder": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk bestelling"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling laden..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten gevonden."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw code"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheime pin"])},
    "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serienummer"])},
    "expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervaldatum"])},
    "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestel id"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "dateAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["op"])}
  },
  "shops": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer Winkels"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkels laden..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten gevonden."])},
    "totalBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal saldo van alle winkels:"])},
    "create": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een nieuwe winkel"])},
      "buttonCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een nieuwe winkel met dezelfde commissies"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een nieuwe winkel"])},
      "copyChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissies gebaseerd op ###SHOPNAME###."])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe winkel aanmaken..."])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "loginPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De login voor de nieuwe winkel"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
      "passwordGenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereren"])},
      "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhaal wachtwoord"])},
      "creditWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krediet waarschuwing"])},
      "canCreateShops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nieuwe winkel kan zijn eigen winkels aanmaken"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])},
      "invalidFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle velden zijn verplicht."])},
      "invalidLoginChars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige tekens in login (alleen alfanumeriek)."])},
      "invalidLoginLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige lengte login (moet minder dan 150 tekens zijn)."])},
      "invalidPasswords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoorden komen niet overeen."])},
      "invalidPasswordLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige wachtwoordlengte (moet minstens 15 tekens zijn)."])},
      "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan, probeer het opnieuw."])}
    },
    "manage": {
      "balance": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer ###SHOPNAME### saldo"])},
        "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
        "buttonGive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overmaken naar winkel"])},
        "buttonTake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overmaken van winkel"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezig met overmaken..."])},
        "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan, probeer het opnieuw."])}
      },
      "commissions": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissies"])},
        "buttonAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen"])},
        "buttonAddAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles Toevoegen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer ###SHOPNAME### commissies"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten laden..."])},
        "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
        "currentCommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidige commissie (max)"])},
        "newCommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe commissie"])},
        "removeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
        "massModify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles aanpassen"])},
        "massRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles verwijderen"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissies bijwerken"])},
        "updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissies bijwerken..."])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissies opgeslaan."])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten gevonden."])},
        "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan, probeer het opnieuw."])}
      },
      "options": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opties"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer ###SHOPNAME### opties"])},
        "titlePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord instellen"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe winkel aanmaken..."])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
        "passwordGenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereren"])},
        "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhaal wachtwoord"])},
        "passwordWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De winkel kan niet langer inloggen met het oude wachtwoord."])},
        "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het wachtwoord van de winkel is bijgewerkt."])},
        "passwordUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord bijwerken"])},
        "shopActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactiveer winkel"])},
        "shopInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeer winkel"])},
        "shopParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkel kan geen winkels meer aanmaken"])},
        "shopNotParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkel kan winkels aanmaken"])},
        "parentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De winkel lijkt winkels onder zich te hebben."])},
        "invalidShopId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoorden komen niet overeen."])},
        "invalidPasswords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoorden komen niet overeen."])},
        "invalidPasswordLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige wachtwoordlengte (moet minstens 15 tekens zijn)."])},
        "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan, probeer het opnieuw."])}
      },
      "transactions": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacties"])}
      }
    }
  },
  "logout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])}
  }
}