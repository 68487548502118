<template>
  <div>
    <h1>{{ welcomeMessage }}</h1>
    <div v-if="pending">
      <svg-icon type="mdi" :path="iconLoading" class="rotate"></svg-icon> {{ $t('shops.loading') }}
    </div>
    <div v-else-if="parent">
      <button v-on:click="this.$router.push({ path: `/shops` })">{{ $t('shops.title') }}</button>
    </div>
  </div>
</template>

<script>
import { apiEndpoint, apiVersionParent } from '@/scripts'
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiLoading, mdiMagnify } from '@mdi/js'
import axios from 'axios'
export default {
  data() {
    return {
      iconLoading: mdiLoading,
      iconSearch: mdiMagnify,
      pending: false,
      error: 0,
      parent: false
    }
  },
  components: {
    SvgIcon
  }, mounted() {
    document.title = this.$t('title') + ' - ' + this.$t('home.title');
    this.$emit("getBalance");
    this.loadParentState();
  }, methods: {
    loadParentState() {
      this.pending = true;
      this.error = 0;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
      };
      axios.get(apiEndpoint + apiVersionParent + "/ping", config)
        .then(res => {
          this.pending = false;
          this.parent = res.data.Result;
        })
        .catch(error => {
          this.pending = false;
          if (error) {
            this.error = 100;
          }
        });
    }
  }, computed: {
    welcomeMessage() {
      return this.$t("home.welcome").replace("###USER###", localStorage.getItem("username"));
    }
  }
}
</script>