<template>
    <img :class="{ 'dontprintthis': dontprintthis }" :src="imgSrc" />
</template>
<script>
export default {
    name: "logo-component",
    data() {
        return {
            imgSrcBecharge: "https://static.becharge.be/img/be/logo.jpg",
            imgSrcBlank: "https://static.becharge.be/webpos/logos/blank.jpg",
            imgSrc: "https://static.becharge.be/webpos/logos/blank.jpg"
        };
    },
    props: {
        dontprintthis: {
            type: Boolean,
            required: false,
        }
    },
    mounted() {
        this.loadImage();
    },
    methods: {
        loadImage() {
            var username = localStorage.getItem("username");
            if (!username) {
                this.imgSrc = this.imgSrcBlank;
                return;
            }
            var parentname = username.split(".")[0];
            var imgToCheck = `https://static.becharge.be/webpos/logos/${parentname}.jpg`;
            fetch(imgToCheck, { method: 'HEAD' })
                .then(res => {
                    if (res.ok) {
                        this.imgSrc = imgToCheck;
                    } else {
                        this.imgSrc = this.imgSrcBecharge;
                    }
                }).catch(err => {
                    console.debug(err);
                    this.imgSrc = this.imgSrcBecharge;
                });
        }
    }
};
</script>