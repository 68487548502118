export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WebPOS"])},
  "buttons": {
    "view": {
      "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher commandes"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher catégories"])},
      "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher marques"])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher produits"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher achats récents"])},
      "recentList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher la liste"])}
    },
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter maintenant"])}
  },
  "pinPopup": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "noPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun code pin n'est actuellement défini. Vous pouvez définir un code pin dans la section"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion..."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login ou mot de passe incorrect"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue ###USER###"])}
  },
  "favorites": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoris"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez actuellement aucun favori. Vous pouvez les marquer en cliquant sur l'étoile sur les produits."])}
  },
  "recent": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récent"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des achats récents..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas d'achats récents."])}
  },
  "recentList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transactions WebPOS"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement de la liste des transactions..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez aucune transaction le ###DATE###"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id commande"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
  },
  "search": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé."])},
    "noInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un terme de recherche."])}
  },
  "browse": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naviguer"])}
  },
  "browseCategories": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des catégories..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé."])}
  },
  "browseBrands": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des marques..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé."])}
  },
  "browseProducts": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des produits..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé."])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres sauvegardés."])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les paramètres"])},
    "currentPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code pin actuel"])},
    "newPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau code pin"])},
    "newPin2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du nouveau code pin"])},
    "removeCurrentPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer code pin"])},
    "forgotPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code pin oublié (déconnexion)"])},
    "noInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez fournir un nouveau code pin."])},
    "incorrectPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code pin actuel incorrect."])},
    "pinsNoMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les nouveaux codes pin ne correspondent pas."])},
    "loadingCreditWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement de avertissement de crédit..."])},
    "creditWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avertissement de crédit"])},
    "creditWarningError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé, veuillez réessayer."])},
    "creditWarningLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur doit être comprise entre 0 et 100000."])},
    "reportmails": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriers de rapport"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des courriers de rapport..."])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avertissement de solde faible"])},
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport de ventes quotidien"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport de ventes hebdomadaire"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport de ventes mensuel"])},
      "balanceShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
      "dailyShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotidien"])},
      "weeklyShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebdomadaire"])},
      "monthlyShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuel"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un e-mail"])},
      "loadingAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout du courrier..."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "loadingRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression du courrier..."])},
      "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail invalide."])},
      "noReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner au moins une option de rapport."])},
      "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé, veuillez réessayer."])}
    }
  },
  "buy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun produit sélectionné."])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])},
    "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone du destinataire"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail de l'acheteur"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code pin"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur de produit non valide."])},
    "invalidTelephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone du destinataire non valide."])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse électronique de l'acheteur non valide."])},
    "invalidPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code pin incorrect."])},
    "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé, veuillez réessayer."])}
  },
  "viewOrder": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher commandes"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des commandes..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre code"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code pin sécret"])},
    "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de série"])},
    "expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'expiration"])},
    "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id commande"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "dateAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])}
  },
  "shops": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les boutiques"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des boutiques..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé."])},
    "totalBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde total de toutes les boutiques :"])},
    "create": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle boutique"])},
      "buttonCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une boutique avec les mêmes commissions"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle boutique"])},
      "copyChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissions basées sur ###SHOPNAME###."])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de la nouvelle boutique..."])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
      "loginPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'identifiant pour la nouvelle boutique"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "passwordGenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
      "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le mot de passe"])},
      "creditWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avertissement de crédit"])},
      "canCreateShops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nouvelle boutique peut créer ses propres boutiques"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
      "invalidFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les champs sont obligatoires."])},
      "invalidLoginChars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractères non valides dans l'identifiant (uniquement alphanumériques)."])},
      "invalidLoginLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur d'identifiant non valide (doit être inférieure à 150 caractères)."])},
      "invalidPasswords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas."])},
      "invalidPasswordLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur de mot de passe non valide (doit comporter au moins 15 caractères)."])},
      "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé, veuillez réessayer."])}
    },
    "manage": {
      "balance": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer le solde de ###SHOPNAME###"])},
        "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
        "buttonGive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer à la boutique"])},
        "buttonTake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer depuis la boutique"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert en cours..."])},
        "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé, veuillez réessayer."])}
      },
      "commissions": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissions"])},
        "buttonAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "buttonAddAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter tous"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les commissions de ###SHOPNAME###"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des produits..."])},
        "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
        "currentCommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission actuelle (max)"])},
        "newCommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle commission"])},
        "removeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "massModify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier en masse"])},
        "massRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlever tout"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour les commissions"])},
        "updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour des commissions en cours..."])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissions sauvegardé."])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé."])},
        "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé, veuillez réessayer."])}
      },
      "options": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les options de ###SHOPNAME###"])},
        "titlePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir un nouveau mot de passe"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'une nouvelle boutique..."])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
        "passwordGenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
        "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le mot de passe"])},
        "passwordWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La boutique ne pourra plus se connecter avec son ancien mot de passe."])},
        "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe de la boutique a été mis à jour."])},
        "passwordUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le mot de passe"])},
        "shopActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver la boutique"])},
        "shopInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer la boutique"])},
        "shopParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La boutique ne peut plus créer de boutiques"])},
        "shopNotParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La boutique peut créer des boutiques"])},
        "parentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La boutique semble avoir des boutiques en dessous d'elle."])},
        "invalidShopId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas."])},
        "invalidPasswords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas."])},
        "invalidPasswordLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur de mot de passe invalide (doit comporter au moins 15 caractères)."])},
        "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé, veuillez réessayer."])}
      },
      "transactions": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])}
      }
    }
  },
  "logout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])}
  }
}