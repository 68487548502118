import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: () => import('../views/FavoritesView.vue')
  },
  {
    path: '/recent',
    name: 'recent',
    component: () => import('../views/RecentView.vue')
  },
  {
    path: '/recent/list/:startDate/:endDate',
    name: 'recentList',
    component: () => import('../views/RecentListView.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/SearchView.vue')
  },
  {
    path: '/browse',
    name: 'browse',
    component: () => import('../views/BrowseCategoriesView.vue')
  },
  {
    path: '/browse/category/:categoryName/:categoryId',
    name: 'browseCategoryId',
    component: () => import('../views/BrowseBrandsView.vue')
  },
  {
    path: '/browse/brand/:brandName/:brandId',
    name: 'browseBrandId',
    component: () => import('../views/BrowseProductsView.vue')
  },
  {
    path: '/buy',
    name: 'buy',
    component: () => import('../views/BuyView.vue')
  },
  {
    path: '/vieworder/:orderId',
    name: 'vieworder',
    component: () => import('../views/ViewOrderView.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/SettingsView.vue')
  },
  {
    path: '/shops',
    name: 'shops',
    component: () => import('../views/ShopView.vue')
  },
  {
    path: '/shops/create/:copyChildName?/:copyChildId?',
    name: 'shopCreate',
    component: () => import('../views/ShopCreateView.vue')
  },
  {
    path: '/shops/manage/balance/:shopName/:shopId',
    name: 'shopManageBalance',
    component: () => import('../views/ShopManageBalanceView.vue')
  },
  {
    path: '/shops/manage/commissions/:shopName/:shopId',
    name: 'shopManageCommissions',
    component: () => import('../views/ShopManageCommissionsView.vue')
  },
  {
    path: '/shops/manage/options/:shopName/:shopId/:shopActive/:shopParent',
    name: 'shopManageOptions',
    component: () => import('../views/ShopManageOptionsView.vue')
  },
  {
    path: '/shops/transactions/:shopName/:shopId/:startDate/:endDate',
    name: 'shopTransactions',
    component: () => import('../views/ShopTransactionsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
