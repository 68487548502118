<template>
  <div id="header">
    <router-link to="/" v-on:click="showNav = false">
      <Logo v-bind="{ dontprintthis: true }" ref="logoChild" />
    </router-link>

    <div id="localeChanger" class="dontprintthis">
      <select v-model="$i18n.locale">
        <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
      </select>
    </div>
    <div id="navButton">
      <button type="button" v-on:click="search()" class="icon">
        <svg-icon type="mdi" :path="iconMenu" v-on:click="showNav = !showNav"></svg-icon>
      </button>
    </div>
  </div>
  <div id="navholder" class="dontprintthis" v-if="authenticated" v-bind:class="{ active: showNav }">
    <nav>
      <router-link to="/" v-on:click="showNav = false">
        <svg-icon type="mdi" :path="iconWallet"></svg-icon> <template v-if="pending">
          <svg-icon type="mdi" :path="iconLoading" class="rotate"></svg-icon>
        </template><template v-else>{{ balance }} {{ currency }}</template>
      </router-link>
      <router-link to="/favorites" v-on:click="showNav = false">
        <svg-icon type="mdi" :path="iconFavorites"></svg-icon> {{ $t('favorites.title') }}
      </router-link>
      <router-link to="/recent" v-on:click="showNav = false">
        <svg-icon type="mdi" :path="iconRecent"></svg-icon> {{ $t('recent.title') }}
      </router-link>
      <router-link to="/search" v-on:click="showNav = false">
        <svg-icon type="mdi" :path="iconSearch"></svg-icon> {{ $t('search.title') }}
      </router-link>
      <router-link to="/browse" v-on:click="showNav = false">
        <svg-icon type="mdi" :path="iconBrowse"></svg-icon> {{ $t('browse.title') }}
      </router-link>
      <router-link to="/settings" v-on:click="showNav = false">
        <svg-icon type="mdi" :path="iconSettings"></svg-icon> {{ $t('settings.title') }}
      </router-link>
      <a href="#" v-on:click="logout()">
        <svg-icon type="mdi" :path="iconLock"></svg-icon> {{ $t('logout.title') }}
      </a>
    </nav>
  </div>

  <router-view @authenticated="setAuthenticated" @setPin="pinWarning" @login="login" @logout="logout" @getBalance="getBalance" @setBalance="setBalance"></router-view>

  <div id="pinWarning" class="dontprintthis" v-if="authenticated && settings.showPinWarning">{{ $t('pinPopup.noPin') }} <router-link to="/settings">
      <svg-icon type="mdi" :path="iconSettings"></svg-icon> {{ $t('settings.title') }}
    </router-link>. <a href="#" v-on:click="pinWarning(false)">
      <svg-icon type="mdi" :path="iconClose"></svg-icon> {{ $t('pinPopup.close') }}.
    </a></div>
</template>


<script>
import { apiEndpoint, apiAuth, apiVersion } from '@/scripts'
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiLoading, mdiStar, mdiHistory, mdiMagnify, mdiShopping, mdiCog, mdiLock, mdiWallet, mdiClose, mdiMenu } from '@mdi/js'
import axios from 'axios'
import Logo from "@/components/Logo";
export default {
  name: 'App',
  components: {
    SvgIcon,
    Logo
  },
  data() {
    return {
      authenticated: false,
      settings: {
        showPinWarning: true
      },
      showNav: false,
      iconLoading: mdiLoading,
      iconFavorites: mdiStar,
      iconRecent: mdiHistory,
      iconSearch: mdiMagnify,
      iconBrowse: mdiShopping,
      iconSettings: mdiCog,
      iconLock: mdiLock,
      iconWallet: mdiWallet,
      iconClose: mdiClose,
      iconMenu: mdiMenu,
      balance: 0,
      currency: "EUR",
      pending: false,
      error: false,
    }
  },
  mounted() {
    this.isAuthenticated();
    if (!this.authenticated) {
      this.refreshToken();
    } else {
      this.getBalance();
    }
    if (localStorage.getItem("settings")) {
      this.settings = JSON.parse(localStorage.getItem("settings"));
      if (this.settings.showPinWarning) {
        this.settings.showPinWarning = this.settings.pin == null;
      }
    }
  },
  watch: {
    $route(to, from) {
      if (to && from) {
        this.isAuthenticated();
      }
    }
  },
  methods: {
    isAuthenticated() {
      if (localStorage.getItem("access_token_refresh_time") != null) {
        if (parseInt(localStorage.getItem("access_token_refresh_time") <= Math.round(new Date().getTime() / 1000))) {
          this.refreshToken();
        } else {
          this.authenticated = true;
        }
      } else {
        this.logout();
      }
    },
    setAuthenticated(status) {
      this.authenticated = status;
      if (!this.authenticated) {
        this.refreshToken();
      }
    },
    login(token) {
      localStorage.setItem("access_token_refresh_time", Math.round(new Date().getTime() / 1000) + token.expires_in - 3600);
      localStorage.setItem("access_token", token.access_token);
      localStorage.setItem("refresh_token", token.refresh_token);
      this.authenticated = true;
      this.$refs.logoChild.loadImage();
    },
    logout() {
      localStorage.removeItem("username");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("access_token_refresh_time")
      this.balance = 0;
      this.authenticated = false;
      this.$router.replace({ name: "login" });
      this.$refs.logoChild.loadImage();
    },
    refreshToken() {
      if (localStorage.getItem("refresh_token") != null) {
        const postVars = {
          grant_type: 'refresh_token',
          refresh_token: localStorage.getItem("refresh_token")
        };
        axios.post(apiEndpoint + apiAuth, postVars)
          .then(res => {
            this.login(res.data);
          })
          .catch(error => {
            if (error) {
              this.logout();
            }
          });
      } else if (this.authenticated) {
        this.logout();
      }
    },
    pinWarning(show) {
      this.settings = JSON.parse(localStorage.getItem("settings"));
      if (this.settings == null) {
        this.settings = {};
      }
      this.settings.showPinWarning = show;
      localStorage.setItem("settings", JSON.stringify(this.settings));
    },
    getBalance() {
      this.pending = true;
      this.error = false;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
      };
      axios.get(apiEndpoint + apiVersion + "/balance", config)
        .then(res => {
          this.balance = res.data.Balance;
          this.currency = res.data.Currency;
          this.pending = false;
        })
        .catch(error => {
          this.pending = false;
          this.error = true;
          if (error.response.status == 401 || error.response.status == 403) {
            this.logout();
          }
        })
    },
    setBalance(balance, currency) {
      this.balance = balance;
      this.currency = currency;
    }
  }
}
</script>


<style lang="scss">
@import 'scss/master.scss';

body {
  padding: 0px;
  margin: 0px;
  font-size: 1rem;
  color: $color_background_dark_text;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#header {
  max-width: 1250px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#header img {
  display: block;

  @include breakpoint(lg) {
    max-width: 150px;
    height: auto;
  }
}

#localeChanger>select {
  border: 0px;
  padding: 10px 25px;
  background-color: $color_base;
  color: $color_base_text;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);

  @include breakpoint(lg) {
    height: 44px;
    padding: 10px;
  }
}

#localeChanger>select>option {
  border: 0px;
  padding: 10px 25px;
  background-color: $color_base_light;
  color: $color_base_light_text;
}

#localeChanger>select>option:hover {
  border: 0px;
  padding: 10px 25px;
  background-color: $color_base_dark;
  color: $color_base_dark_text;
}

#navButton {
  text-align: left;
  margin: 0px 0px 0px 10px;
  display: none;

  @include breakpoint(lg) {
    display: block;
    margin: 0px 10px 0px 0px;
  }
}

#navholder {
  padding: 0px;
  background-color: $color_base;
  color: $color_base_text;

  @include breakpoint(lg) {
    display: none;
  }
}

#navholder.active {
  display: block;
}

nav {
  max-width: 1250px;
  margin: 25px auto;
  display: flex;
  justify-content: space-around;

  @include breakpoint(lg) {
    flex-wrap: wrap;
  }

  a {
    flex: 1 1 0;
    text-align: left;
    padding: 10px 10px;
    text-decoration: none;
    color: $color_base_text;

    @include breakpoint(lg) {
      flex-basis: 100%;
    }

    &.router-link-exact-active {
      background-color: $color_base_light;
      color: $color_base_light_text;
    }
  }

  a>svg {
    margin-right: 15px;
  }

  a:hover {
    background-color: $color_base_dark;
    color: $color_base_dark_text;
  }
}


#pinWarning {
  position: fixed;
  bottom: 0px;
  padding: 10px;
  width: 100%;
  background-color: $color_base_light;
  color: $color_base_light_text;
}
</style>
