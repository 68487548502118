export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WebPOS"])},
  "buttons": {
    "view": {
      "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View order"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View categories"])},
      "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View brands"])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View products"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View recent purchases"])},
      "recentList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View list"])}
    },
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy now"])}
  },
  "pinPopup": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "noPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is currently no pin set. You can set a pin in the"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logging in..."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login or password incorrect"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back ###USER###"])}
  },
  "favorites": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You currently have no favorites. You can mark them by clicking the star on the products."])}
  },
  "recent": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading recent purchases..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no recent purchases."])}
  },
  "recentList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WebPOS transactions"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading transaction list..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no transactions on ###DATE###"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order id"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
  },
  "search": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found."])},
    "noInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input a search term."])}
  },
  "browse": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse"])}
  },
  "browseCategories": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading categories..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found."])}
  },
  "browseBrands": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading brands..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found."])}
  },
  "browseProducts": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading products..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found."])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings saved."])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save settings"])},
    "currentPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current pin"])},
    "newPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New pin"])},
    "newPin2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New pin confirmation"])},
    "removeCurrentPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove pin"])},
    "forgotPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot pin (logout)"])},
    "noInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please supply a new pin number."])},
    "incorrectPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect current pin."])},
    "pinsNoMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New pin codes do not match."])},
    "loadingCreditWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading credit warning..."])},
    "creditWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit warning"])},
    "creditWarningError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again."])},
    "creditWarningLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value should be between 0 and 100000."])},
    "reportmails": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report mails"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading report mails..."])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low balance warning"])},
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily sales report"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly sales report"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly sales report"])},
      "balanceShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
      "dailyShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
      "weeklyShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
      "monthlyShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add e-mail"])},
      "loadingAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding mail..."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
      "loadingRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removing mail..."])},
      "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid e-mail address."])},
      "noReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to select at least one reporting option."])},
      "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again."])}
    }
  },
  "buy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No product selected."])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver telephone"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyer e-mail"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid product value."])},
    "invalidTelephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid recipient telephone number."])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid recipient email address."])},
    "invalidPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong pin."])},
    "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again."])}
  },
  "viewOrder": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Order"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading order..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your code"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret pin"])},
    "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial"])},
    "expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiry"])},
    "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order id"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "dateAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])}
  },
  "shops": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Shops"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading shops..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found."])},
    "totalBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total balance of all shops:"])},
    "create": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new shop"])},
      "buttonCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new shop with the same commissions"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new shop"])},
      "copyChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissions based on ###SHOPNAME###"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating new shop..."])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "loginPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The login for the new shop"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "passwordGenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
      "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
      "creditWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit warning"])},
      "canCreateShops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The new shop can create their own shops"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "invalidFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All fields are required."])},
      "invalidLoginChars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid characters in login (alphanumeric only)."])},
      "invalidLoginLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid login length (should be less then 150)."])},
      "invalidPasswords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match."])},
      "invalidPasswordLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid password length (should be at least 15)."])},
      "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again."])}
    },
    "manage": {
      "balance": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage ###SHOPNAME### balance"])},
        "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
        "buttonGive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to shop"])},
        "buttonTake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer from shop"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferring ..."])},
        "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again."])}
      },
      "commissions": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissions"])},
        "buttonAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "buttonAddAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add All"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage ###SHOPNAME### commissions"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading products ..."])},
        "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
        "currentCommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current commission (max)"])},
        "newCommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New commission"])},
        "removeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "massModify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mass modify"])},
        "massRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove all"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update commissions"])},
        "updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updating commissions ..."])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissions saved."])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found."])},
        "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again."])}
      },
      "options": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage ###SHOPNAME### options"])},
        "titlePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new password"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating new shop..."])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "passwordGenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
        "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
        "passwordWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The shop will no longer be able to login using their old password."])},
        "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The shop's password has been updated."])},
        "passwordUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Password"])},
        "shopActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate shop"])},
        "shopInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate shop"])},
        "shopParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop can no longer create shops"])},
        "shopNotParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop can create shops"])},
        "parentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The shop seems to have shops under it."])},
        "invalidShopId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match."])},
        "invalidPasswords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match."])},
        "invalidPasswordLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid password length (should be at least 15)."])},
        "ohno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again."])}
      },
      "transactions": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])}
      }
    }
  },
  "logout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  }
}